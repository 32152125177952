<template>
  <CContainer :fluid="true">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Papéis</h4>
            <router-link
              to="/permissions/create"
              class="btn btn-primary d-flex align-items-center"
            >
              <CIcon name="cil-library-add" class="mr-2" />

              Incluir
            </router-link>
          </CCardHeader>

          <CCardBody>
            <CRow>
              <CCol lg="12">
                <Search value="" @search="updateSearch" />
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12">
                <DataTable
                  :sorter="query.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/permissions/edit/"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                  @update:checked="updateStatus"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/permissions/edit/${id}`" />
                    <DatatableRemove
                      @row-clicked="handleRowClick"
                      @deleted="deleteRole"
                    />
                  </template>
                </DataTable>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol lg="12">
                <Pagination
                  :page="query.page"
                  :per-page="query.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="(page) => (query.page = page)"
                  @update:perpage="(perpage) => (query.perpage = perpage)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'

import RoleService from '@/services/role.service'

export default {
  metaInfo: {
    title: 'Papéis',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_permissions')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    DataTable,
    DatatableEdit,
    DatatableRemove,
    Pagination,
    Search
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Nome'
          },
          {
            key: 'slug',
            label: 'Slug'
          },
          {
            key: 'description',
            label: 'Descrição'
          },
          {
            key: 'actions',
            label: 'Ações',
            sorter: false
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: '',
        sorter: {
          asc: false,
          column: ''
        }
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      rowCliked: {}
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.updateDataTable()
  },

  methods: {
    async updateDataTable() {
      const response = await RoleService.getAll(this.query)
      const { total, lastPage: pages } = response

      if (response.data) {
        this.pagination = {
          total,
          pages,
          items: response.data.length
        }

        const newItems = response.data.map((item) => {
          return {
            id: item.id,
            slug: item.slug,
            name: item.name,
            description: item.description
          }
        })

        this.datatable.items = newItems
      }
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1
      await this.updateDataTable()
    },

    handleRowClick(event) {
      this.rowCliked = event
    },

    async updateStatus(event) {
      const response = await RoleService.updateStatus(this.rowCliked, event)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Status alterado com sucesso'
        })
        await this.updateDataTable()
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Ocorreu um erro ao atualizar o status, por favor tente novamente!'
        })
      }
    },

    async deleteRole() {
      const response = await RoleService.deleteRole(this.rowCliked)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Papel excluído com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não é possivel excluir o papel'
        })
      }

      await this.updateDataTable()
    }
  }
}
</script>
